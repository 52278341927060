<template>
    <div class="page">
        <div class="my-top">
            <!-- <img src="../../assets/images/wodebeijingban.png" alt="" style=" height: 300px;width: 100%;">-->
            <div class="info-box" style="">
                <div class="head-portrait">
                    <van-image
                            round
                            width="65px"
                            height="65px"
                            :src="userInfo.avatar"
                    />
                </div>
                <div class="info-right">
                    <div class="name">{{ userInfo.nickname }}</div>
                    <div class="phone">
                        <div>
                            {{ userInfo.phone }}
                        </div>
                        <div class="card" @click="go('my-user-authentication')">
                            修改资料
                            <van-icon color="#FFFFF" size="14" name="arrow" class="float-right" />
                        </div>
                    </div>
                    <div>
                        <van-button plain hairline round type="info" size="small" class="option status-btn" v-if="userInfo.businessState!='SUCCESS'">
                            已认证
                        </van-button>
                        <van-button plain hairline round type="info" size="small" class="option status-btn" v-if="userInfo.businessState=='SUCCESS'">
                            {{ userInfo.businessState=='REVIEW'?'审核中':userInfo.businessState=='SUCCESS'?'已认证': userInfo.businessState=='NOTAPPLIED'?'未认证':'认证失败'}}
                        </van-button>
                        <!--                        <van-button plain hairline round type="info" size="small" class="option"-->
                        <!--                                    style="height: 20px;margin-top: 10px" @click="changeStatus">添加状态-->
                        <!--                        </van-button>-->
                        <!-- 弹出选择 -->
                        <van-popup v-model="show" :style="{ height: '30%',width:'80%' }">
                            工作状态
                        </van-popup>
                    </div>
                </div>
            </div>
            <div class="my-wrap" style="">
                <div>
                    <p>0</p>
                    <p>我的投递</p>
                </div>
                <div @click="goMyTreasure">
                    <p>{{userInfo.treasureNum}}</p>
                    <p>我的元宝</p>
                </div>
<!--                <div style="color:#F3C269">-->
<!--                  <p style="margin-block-start: 0.6em;margin-block-end: 0.6em;">-->
<!--                    <van-button style="color:#F3C269"  round type="info" size="mini" class="upgrade">元宝提现</van-button>-->
<!--                  </p>-->
<!--                  <p style="margin-block-start: 0.2em;margin-block-end: 0.6em;">-->
<!--                    <van-button style="color:#F3C269"  round type="info" size="mini" class="upgrade">元宝充值</van-button>-->
<!--                  </p>-->
<!--                </div>-->
                <div @click="goMyIntegral">
                    <p>{{userInfo.integralNum}}</p>
                    <p>我的积分</p>
                </div>
            </div>
            <div class="insert-picture">
                <div class="pic-content">
                    <span style="">我的身份-{{ userInfo.businessState!='SUCCESS'?'工人':'项目经理' }}</span>
                    <van-button plain hairline round type="info" size="small" class="upgrade" v-if="userInfo.businessState!='SUCCESS'" @click="go('my-authentication')">
                        升级至老板
                    </van-button>
                </div>
            </div>
        </div>

        <div class="list-box">
            <div class="my-list-cell">
<!--                <div class="resume-cell">-->
<!--                    <van-icon name="todo-list" color="#1083FC"/>-->
<!--                    <span class="distance">我的简历</span>-->
<!--                    <div class="eidt-font" @click="go('my-user-authentication')">-->
<!--                        在线编辑-->
<!--                        <van-icon name="arrow"/>-->
<!--                    </div>-->
<!--                </div>-->
                <div class="resume-cell">
                    <van-icon name="comment-circle" color="#1083FC"/>
                    <span class="distance">发布招工需求</span>
                    <div class="eidt-font" @click="go('demand')">
                        去发布
                        <van-icon name="arrow"/>
                    </div>
                </div>
                <!--                <div class="resume-cell">-->
                <!--                    <van-icon name="umbrella-circle" color="#1083FC"/>-->
                <!--                    <span>信用值</span>-->
                <!--                    <div class="eidt-font" @click="eidtResume">-->
                <!--                        在线编辑-->
                <!--                        <van-icon name="arrow"/>-->
                <!--                    </div>-->
                <!--                </div>-->
                <div class="resume-cell" v-if="role=='ADMIN'">
                    <van-icon name="info" color="#1083FC"/>
                    <span class="distance">信息审核</span>
                    <div class="eidt-font" @click="go('admin-demand-list')">
                        审核信息
                        <van-icon name="arrow"/>
                    </div>
                </div>
                <div class="resume-cell">
                    <van-icon name="more" color="#1083FC"/>
                    <span class="distance">我的招工信息</span>
                    <div class="eidt-font" @click="go('my-demand-list')">
                        我的需求
                        <van-icon name="arrow"/>
                    </div>
                </div>
                <div class="resume-cell">
                    <van-icon name="friends" color="#1083FC"/>
                    <span class="distance">邀请注册</span>
                    <div class="eidt-font" @click="go('my-invite')">
                        邀请用户
                        <van-icon name="arrow"/>
                    </div>
                </div>
                <div class="resume-cell">
                    <van-icon name="setting" color="#1083FC"/>
                    <span class="distance">设置</span>
                    <div class="eidt-font" @click="go('set-up')">
                        在线编辑
                        <van-icon name="arrow"/>
                    </div>
                </div>
            </div>

        </div>

        <!-- 弹出选择 -->
        <van-popup v-model="show" :style="{ height: '33%',width:'80%' }" closeable>
            <div class="pop-up-icon">
                <van-icon name="todo-list-o" color="#6BA4E0" size="40"/>
                <div>
                    <h4>工作状态</h4>
                    <van-radio-group v-model="radio" class="workStatus">
                        <van-radio name="1" class="radio">干活中</van-radio>
                        <van-radio name="2" class="radio">找活中</van-radio>
                        <van-radio name="3" class="radio">活快干完了,找机会</van-radio>
                    </van-radio-group>
                    <div style="margin: 16px;">
                        <van-button round block type="info" native-type="submit">提交</van-button>
                    </div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
    import store from "../../store"
    import {getUserInfo} from "../../api/user";

    export default {
        data() {
            return {
                user: {},
                userInfo: {},
                params: {
                    username: undefined,
                    status: undefined,
                    page: 0,
                    size: 40,
                },
                show: false,
                radio: '1',
                role: ''
            }
        },
        mounted() {
            // this.user = JSON.parse(`${store.state.user}`)
            this.loadUserInfo();
            console.info("USER,user:", this.user)
            this.role = `${store.state.role}`
        },
        methods: {
            async loadUserInfo() {
                let r = await getUserInfo();
                this.userInfo = r.data
            },
            go(url) {
                this.$router.push(url);
            },
            changeStatus() {
                this.show = true;
                console.log(11111)
            },
            goMyTreasure() {
                this.$router.push(`my-treasure`)
            },
            goMyIntegral() {
                this.$router.push(`my-integral`)
            },
            eidtResume() {
                this.$router.push(`my-user-authentication`)
            },
            goDemandRelease() {
                this.$router.push(`release`)
            },
            myCredit() {
                this.$router.push(`my-credit`)
            },
            setUp() {
                this.$router.push(`set-up`)
            },
        },
        computed: {}
    }

</script>

<style lang="less" scoped>
    .my-top {
        height: 210px;
        width: 100%;
        background: linear-gradient(to right,#0d8ffc,#0d93fc);

        .info-box {
            width: 100%;
            display: flex;
            justify-content: space-around;
            height: 90px;

            .head-portrait {
                margin-top: 15px;
                padding: 5px 0 5px 5px;
                text-align: center;

            }

            .info-right {
                margin-top: 10px;
                padding: 5px;
                width: 70%;
                height: 90px;
                color: #FFFFFF;

                .name {
                    margin-top: 5px;
                    font-size: 16px;
                    font-weight: 600;
                }

                .phone {
                    font-size: 14px;
                    margin-top: 5px;
                    display: flex;
                    justify-content: space-between;

                    .card {
                        margin-left: 30%;
                    }
                }

                .status-btn {
                    height: 20px;
                    margin-top: 5px
                }
            }
        }

        .insert-picture {
            max-width: 700px;
            border-radius: 16px 16px 0 0 ;
            width: 90%;
            margin: 0;
            height: 50px;
            position: absolute;
            left: 50%;
            transform: translate(-50%, 30px);
            background-image: url("../../assets/images/jiaose.png");
            color: #FFFFFF;

            .pic-content {
                margin-top: 4%;

                span {
                    margin-left: 15px
                }

                .upgrade {
                    height: 25px;
                    float: right;
                    color: #F3C269;
                    margin-right: 10px;
                    width: 100px;
                    border: none;
                }

            }
        }

        .my-wrap {
            width: 100%;
            height: 70px;
            margin-top: 5px;
            display: flex;
            justify-content: space-around;
            color: #FFFFFF;

            div {
                text-align: center;
            }
        }

    }

    .list-box {
        border-radius: 25px;
        height: calc(100vh - 210px);
        margin-top: 15px;
        background: #FFFFFF;

        .my-list-cell {
            margin-top: 20px;
            position: absolute;
            width: 100%;

            .resume-cell {
                padding: 10px;
                margin-left: 5px;
                border-bottom: 1px solid #F7F7F7;
                color: #212121;
                font-size: 18px;
                line-height: 25px;
                .distance {
                    margin-left: 5px;
                }

                .eidt-font {
                    color: #C3C3C3;
                    font-size: 14px;
                    float: right;
                    margin-left: -100%;
                }
            }
        }
    }

    .pop-up-icon {
        margin-top: 10px;
        text-align: center;

        .workStatus {
            margin-left: 25%;

            .radio {
                margin-top: 5px;
            }
        }
    }

</style>
